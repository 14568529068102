.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
/* 
button {
  padding: 10px 20px;
  font-size: 1.2rem;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
} */

button:hover {
  background-color: #45a049;
}

h1, h2, p {
  text-align: center;
}









@media only screen and (max-width: 768px) {
  /* Smaller screens (tablets, mobile) */
  h1 {
    font-size: 2rem;
  }
  
  button {
    font-size: 1rem;
    padding: 8px 15px;
  }

  .progress-container {
    flex-direction: column;
    align-items: center;
  }
  .result-page-witd {
    width: 70% !important;
  }
  .options-col {
    width: 40% !important;
  }
}

@media only screen and (max-width: 600px) {
  /* Mobile screens */
  h1 {
    font-size: 20px !important;
  }
  .progress-bar-container {
    width: 58% !important;
  }

  button {
    font-size: 0.9rem;
    padding: 6px 10px;
  }
  .options-col {
    width: 100% !important;
  }
  .previous-button, .next-button {
    width: 48% !important;
    margin: 3px !important;
  }
  .result-page-witd {
    width: 100% !important;
    padding: 50px 15px !important;
  }
  .return-button, .share-button {
    padding: 10px 0px !important;
    font-size: 15px !important;
    margin: 2px !important;
    width: 48% !important;
  }
}
