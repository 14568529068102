/* Style for the Start Page container */
.start-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
  background-color:rgb(255, 254, 254);
  padding: 20px;
  text-align: center;
}

h1 {
  margin-top: 50px;
  font-size: 2.5rem;
  
  color: #333;
}

.description {
  font-size: 1.2rem;
  color:#ffffff;
  max-width: 600px;
  margin-bottom: 30px;
}

/* Style for the start button */
.start-button {
  background-color: transparent;
  color: white;
  padding: 15px 70px;
  font-size: 1.2rem;
  border: 2px solid;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-color: white;
}

/* Hover effect for the button */
.start-button:hover {
  background-color: white;
  color: black;
}

/* Active state effect for click feedback */
/* .start-button:active {
  background-color: #003d80;
} */
.check {
  padding: 50px 20px;
  background-color: #4caf50b5;
  border-radius: 20px;
}
.quiz-tit{
  color: white;
  margin: 0;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Starts below its original position */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Moves to its original position */
  }
}

.quiz-tit,.start-image,.description,.start-button {
  animation: fadeUp 0.8s ease-out; /* Animation for fading and moving up */
}



