/* Container for the quiz page */
.quiz-container {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

/* Header containing the question index and progress bar */
.quiz-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Question index styling */
.question-index {
  font-size: 18px;
  font-weight: bold;
}

/* Progress bar container */
.progress-bar-container {
  width: 40%;
  height: 20px;
  background-color: #e0e0e0;  /* Light background */
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}

/* Progress bar */
.progress-bar {
  height: 100%;
  background-color: #4caf50;  /* Green progress bar */
  border-radius: 5px;
  transition: width 0.5s ease-in-out; /* Add smooth transition */

}

.progress-text{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 20px;
        font-size: 13px;
}

/* Question content styling */
.question-content {
  margin: 20px 0;
  /* display: flex;
  justify-content: center; */
}

/* Answer options */
.options {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Each option label styling */
.option-label {
  margin: 10px 0;
  cursor: pointer;
  display: inline-block;
  border: 2px solid;
  border-color: #4caf50b5;
  padding: 10px;
  border-radius: 8px;
}

/* Highlight selected option */
.option-label.selected {
  font-weight: bold;
  background-color: #4caf50b5;
  color: white;
  border-color: #4caf50b5;
  /* border:2px solid; */
}

/* Button container styling */
.button-container {
  margin-top: 20px;
}

/* Previous and Next button styling */
.previous-button,
.next-button {
  padding: 15px 0px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: black;
  border: 2px solid;
  border-radius: 30px;
  margin: 10px;
  width: 15%;
}

.previous-button:hover,
.next-button:hover {
  background-color:black;
  color: white;
}

.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  border: 2px solid;
  color: white;
  border-color: #ccc;
}
.options-col{
  display: flex;
  flex-direction: column;
  width: 25%;

}

/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Style the label */
.option-label {
  display: block;
  position: relative;
  /* padding-left: 30px; */
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

/* Initially hide the custom radio button circle */
.custom-radio {
  display: none; /* Hidden by default */
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: white;
}

/* Show the custom radio button only when the input is checked */
.option-label input:checked + .custom-radio {
  display: block;
  background-color: #000;
}

/* Add a tick mark when the radio button is checked */
.option-label input:checked + .custom-radio::after {
  content: "✔";
  position: absolute;
  left: 3px;
  top: -2px;
  font-size: 17px;
  color: white;
}

/* .question {
  border: 2px solid;
  border-color: gray;
  border-radius: 5px;
  padding: 30px 30px;
} */

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Starts below its original position */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Moves to its original position */
  }
}

.fadeUp {
  animation: fadeUp 0.8s ease-out; /* Animation for fading and moving up */
}

