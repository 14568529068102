.result-page-container {
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  }
  .quiz-tit-result{
    color: white;
    margin: 0;
    
  }
  .result-page-witd{
    width: 40%;
    background-color: #4caf50b5;
    color: white;
    border-radius: 20px;
    padding: 50px 30px;

  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .dev-result{
    display: flex;
    justify-content: center;
  }
  .result-text{
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.5;
    width: 100%;
  }
  
  .return-button,
  .share-button {
    padding: 15px 40px;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 2px solid;
    border-radius: 30px;
    border-color: white;
  }
  
  .return-button:hover,
  .share-button:hover {
    background-color: black;
    color: white;
    border-color: black;
  }
  .result-heading {
    color: white;
    text-decoration: underline;
    margin-top: 0;
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Starts below its original position */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Moves to its original position */
  }
}

.fadeUp,.quiz-tit-result,.result-heading,.result-text,.return-button,.share-button,.result-team-img {
  animation: fadeUp 0.8s ease-out; /* Animation for fading and moving up */
}
  